<template>
  <b-card title="">
    <b-row>
      <!-- {{ groups }} -->
      <!-- {{ applicationOptions }} -->
      <!-- {{ groupOptions }} -->
      <b-col cols="6">
        <!-- <p>{{ token }}</p> -->
        <!-- <p>{{ app_options }}</p> -->
        <h3 class="text-primary">Create User</h3>
      </b-col>
      <b-col cols="6">
        <b-button
          variant="primary"
          class="btn btn-primary float-right"
          @click="back()"
          size="sm"
        >
          Back
        </b-button>
      </b-col>
      <b-col cols="12">
        <validation-observer ref="simpleRules" #default="{ invalid }">
          <b-form @submit.prevent>
            <b-row>
              <!-- email -->
              <b-col cols="4">
                <b-form-group label="Email" label-for="v-email">
                  <!-- <validation-provider
                    #default="{ errors }"
                    name="Email"
                    rules="required|email"
                  > -->
                    <b-form-input
                      id="v-email"
                      type="email"
                      placeholder="Email"
                      v-model="email"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
              </b-col>
              <!-- First name -->
              <b-col cols="4">
                <b-form-group label="First name" label-for="v-first_name">
                    <b-form-input
                      id="v-first_name"
                      placeholder="Enter First Name"
                      v-model="first_name"
                    />
                </b-form-group>
              </b-col>
              <!-- last name -->
              <b-col cols="4">
                <b-form-group label="Last name" label-for="v-last_name">
                  <b-form-input
                    id="v-last_name"
                    placeholder="Enter Last Name"
                    v-model="last_name"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Groups" label-for="v-groups">
                  <b-form-select v-model="groups" @change="fetchGroup()">
                    <b-form-select-option
                      v-for="group in groupOptions"
                      :key="group.id"
                      :value="group"
                      >{{ group.name }}</b-form-select-option
                    >
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col cols="6">
                <b-form-group label="Application" label-for="v-applications">
                  <v-select
                    v-model="applications"
                    multiple
                    :options="app_options"
                    label="application_name"
                    taggable
                  />
                </b-form-group>
              </b-col>

              <!-- submit and reset -->
              <b-col cols="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1 mt-1"
                  @click.prevent="addUser()"
                  :disabled="invalid"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BButton,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
// import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import vSelect from "vue-select";
export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BButton,
    BCard,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      email: "",
      groups: [],
      first_name:"",
      last_name:"",
      groupOptions: [],
      applications: [],
      applicationOptions: [],
      token: "",
      items: [],
      app_options: [],
    };
  },
  mounted() {
    // this.fetchGroup();
    this.token = this.$store.state.app.token;
  },
  created() {
    this.fetchGroup();
    this.fetchApplication();
    // (this.tenantid = this.$store.getters.tenantid),
    //   (this.accesstoken = this.$store.getters.token);
    //   console.log(this.tenantid);
  },
  methods: {
    back() {
      this.$router.push("/eca-user");
    },
    fetchGroup() {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + "user/groups/",
      };
      this.$http(options)
        .then((res) => {
          this.groupOptions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchApplication: function () {
      const options = {
        method: "GET",
        // headers: {
        //   Authorization: `Bearer ${localStorage.getItem("JWTtoken")}`,
        //   "content-type": "application/json",
        // },
        url: process.env.VUE_APP_BASEURL + "application/applications/",
      };
      this.$http(options).then((res) => {
        this.items = res.data;
        this.applicationOptions = res.data.results;
        this.applicationOptions.forEach((ele) => {
          this.app_options.push(ele);
        });
      });
    },
    addUser() {
      var self = this;
      const body = {
        email: this.email,
        first_name: this.first_name,
        last_name: this.last_name,
        username: this.email,
        // groups: this.groups,
        applications: this.applications,
      };
      if (this.groups) {
        let groups_list = [];
        groups_list.push(this.groups);
        body["groups"] = groups_list;
      }
      const options = {
        method: "POST",
        data: body,
        url: process.env.VUE_APP_BASEURL + `user/users/`,
      };
      this.$http(options)
        .then((res) => {
          if (res.data.status === "error") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.data.message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Created Successfully",
                icon: "EditIcon",
                variant: "success",
              },
            });
            this.$router.push("/eca-user");
          }
        })
        .catch((err) => {
          console.log(err);
          self.$toast({
            component: ToastificationContent,
            props: {
              title: err.data.message,
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
